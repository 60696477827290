import React, { useState, useEffect, useRef } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { css, useTheme } from "@emotion/react"
import { fluidRange } from "polished"
import get from "lodash/get"
import { useDispatch } from "react-redux"
import { gsap, ScrollTrigger } from "gsap/all"

import { setDuration } from "../store/transition"
import { percentage, randomInteger, fluid } from "../utilities/functions"
import BaseSvg from "../components/BaseSvg"
import BaseImage from "../components/BaseImage"
import BaseFigureCaseStudy from "../components/BaseFigureCaseStudy"
import MotionBackground from "../components/MotionBackground"
import MotionReveal from "../components/MotionReveal"

if (typeof window !== "undefined") {
  gsap.registerPlugin(ScrollTrigger)
}

export default function FrontPageCaseStudies() {
  const data = useStaticQuery(graphql`
    query {
      allDataJson(sort: { fields: order }, filter: { theme: { ne: null } }) {
        edges {
          node {
            fields {
              slug
            }
            title
            tags
            theme
            images {
              foreground {
                src {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
                  }
                }
              }
              background {
                src {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
                  }
                }
              }
              featured {
                src {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
                  }
                }
              }
              auxiliaryOne {
                src {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
                  }
                }
              }
              auxiliaryTwo {
                src {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
                  }
                }
              }
            }
            imagePositions {
              auxiliaryOne {
                min {
                  top
                  left
                }
                max {
                  top
                  left
                }
                width
                height
              }
              auxiliaryTwo {
                min {
                  top
                  left
                }
                max {
                  top
                  left
                }
                width
                height
              }
              main {
                min {
                  top
                  left
                }
                max {
                  top
                  left
                }
                width
                height
              }
            }
            backgroundText
          }
        }
      }
    }
  `)

  return (
    <>
      <FrontPageCaseStudiesCollection data={data} />
    </>
  )
}

function FrontPageCaseStudiesCollection({ data }) {
  const items = data.allDataJson.edges.map(item => {
    return {
      slug: item.node.fields.slug,
      title: item.node.title,
      tags: item.node.tags,
      theme: item.node.theme,
      imagePositions: item.node.imagePositions,
      imageForeground: get(item, "node.images.foreground.src"),
      imageBackground: get(item, "node.images.background.src"),
      imageFeatured: get(item, "node.images.featured.src"),
      imageAuxiliaryOne: get(item, "node.images.auxiliaryOne.src"),
      imageAuxiliaryTwo: get(item, "node.images.auxiliaryTwo.src"),
    }
  })

  css.section = css`
    display: block;
    pointer-events: none;
  `

  return (
    <section css={css.section}>
      {items.map((item, index) => (
        <FrontPageCaseStudiesItem key={index} {...item} />
      ))}
    </section>
  )
}

function FrontPageCaseStudiesItem({
  slug,
  title,
  theme,
  imageForeground,
  imageBackground,
  imageFeatured,
  imageAuxiliaryOne,
  imageAuxiliaryTwo,
  backgroundText,
  imagePositions,
}) {
  const themeGlobal = useTheme()
  const [transition, setTransition] = useState(false)
  const dispatch = useDispatch()
  const ref = useRef(null)
  const refPin = useRef(null)
  const refHeadingEnter = useRef(null)
  const handleClick = event => {
    setTransition(Date.now())
    dispatch(setDuration(1200))
  }

  css.section = css`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 25vh 0;
  `

  css.container = css`
    ${themeGlobal.mixins.container.xl}
    width: 100%;
    max-width: 1520px;
  `

  css.grid = css`
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 40px;
    align-items: center;
    height: 100vh;

    @media (min-width: ${themeGlobal.breakpoints.tablet}) {
      grid-template-columns: repeat(12, 1fr);
    }
  `

  css.gridItem = css`
    position: relative;
    grid-column: 1;

    &:nth-of-type(1) {
      mix-blend-mode: difference;

      @media (min-width: ${themeGlobal.breakpoints.tablet}) {
        grid-column: 1/11;
        grid-row: 1;
      }
    }
  `

  css.figure = css`
    padding-bottom: ${percentage(540, 770, "%")};
    pointer-events: none;
  `

  css.link = css`
    display: inline-flex;
    text-decoration: none;
  `

  css.heading = css`
    margin: 0;
    ${
      "" /* ${fluidRange(
      {
        prop: "fontSize",
        fromSize: "80px",
        toSize: "180px",
      },
      themeGlobal.breakpoints.mobile,
      themeGlobal.breakpoints.desktop
    )} */
    }
    ${fluid(
      {
        prop: "fontSize",
        fromSize: `80px`,
        toSize: `180px`,
      },
      themeGlobal.breakpoints.mobile,
      themeGlobal.breakpoints.desktop
    )}
    font-weight: ${themeGlobal.fontWeight.bold};
    line-height: 1;
    white-space: pre-wrap;
    color: ${themeGlobal.colors.global.light};
    mix-blend-mode: difference;
  `

  return (
    <MotionBackground
      trigger={ref}
      disableLeave={true}
      color={themeGlobal.colors[theme].block}
    >
      <FrontPageCaseStudiesItemMotion
        trigger={ref}
        color={themeGlobal.colors[theme].block}
        disableLeave={true}
        label={theme}
      >
        <section ref={ref} css={css.section} className="panel">
          {imagePositions && imagePositions.auxiliaryOne && (
            <FrontPageCaseStudiesItemImage
              {...imagePositions.auxiliaryOne}
              background={themeGlobal.colors[theme].one}
            >
              {imageAuxiliaryOne && (
                <Link
                  to={slug}
                  className="cursor cursor--case-study"
                  onClick={handleClick}
                >
                  <BaseImage src={imageAuxiliaryOne} css={css.image} />
                </Link>
              )}
            </FrontPageCaseStudiesItemImage>
          )}
          {imagePositions && imagePositions.auxiliaryTwo && (
            <FrontPageCaseStudiesItemImage
              {...imagePositions.auxiliaryTwo}
              background={themeGlobal.colors[theme].one}
            >
              {imageAuxiliaryTwo && (
                <Link
                  to={slug}
                  className="cursor cursor--case-study"
                  onClick={handleClick}
                >
                  <BaseImage src={imageAuxiliaryTwo} css={css.image} />
                </Link>
              )}
            </FrontPageCaseStudiesItemImage>
          )}
          {imagePositions && imagePositions.main && (
            <FrontPageCaseStudiesItemImage
              {...imagePositions.main}
              imageForeground={imageForeground}
              background={themeGlobal.colors[theme].one}
            >
              <Link
                to={slug}
                className="cursor cursor--case-study"
                onClick={handleClick}
              >
                <BaseFigureCaseStudy
                  imageForeground={imageForeground}
                  imageBackground={imageBackground}
                  theme={theme}
                  backgroundText={backgroundText}
                  transitionTrigger={transition}
                />
              </Link>
            </FrontPageCaseStudiesItemImage>
          )}
          <div css={css.container}>
            <div css={css.grid}>
              <div css={css.gridItem}>
                <MotionReveal
                  trigger={ref}
                  pin={refPin}
                  headingEnter={refHeadingEnter}
                >
                  <div ref={refPin}>
                    <Link
                      to={slug}
                      css={css.link}
                      className="cursor cursor--case-study"
                      onClick={handleClick}
                    >
                      <h2
                        ref={refHeadingEnter}
                        css={css.heading}
                        className="heading"
                        dangerouslySetInnerHTML={{
                          __html: title,
                        }}
                      />
                    </Link>
                  </div>
                </MotionReveal>
              </div>
            </div>
          </div>
        </section>
      </FrontPageCaseStudiesItemMotion>
    </MotionBackground>
  )
}

function FrontPageCaseStudiesItemImage(props) {
  const {
    children,
    min,
    max,
    width,
    height,
    background,
    imageForeground,
    ...other
  } = props
  const ref = useRef()
  const refImage = useRef()
  const theme = useTheme()
  const canvasWidth = 1920
  const canvasHeight = 1080
  const distance = useRef(randomInteger(25, 100))
  const speed = useRef(randomInteger(0, 200))

  useEffect(() => {
    const element = ref.current
    const elementSpeed = speed.current
    const timeline = gsap.timeline({ paused: true })

    timeline.fromTo(
      element,
      {
        yPercent: elementSpeed / 2,
      },
      {
        yPercent: elementSpeed / -2,
        duration: 1,
      },
      0
    )

    const scrollTrigger = ScrollTrigger.create({
      animation: timeline,
      trigger: element,
      scrub: true,
    })

    return () => {
      scrollTrigger.kill()
    }
  }, [])

  useEffect(() => {
    const element = refImage.current
    const elementDistance = distance.current
    let mouseX = 0
    let mouseY = 0
    const viewportCenterX = window.innerWidth / 2
    const viewportCenterY = window.innerHeight / 2

    const updateMouse = event => {
      mouseX = event.x
      mouseY = event.y
    }

    const animateMouse = () => {
      const percentageFromViewportCenterX = mouseX / viewportCenterX
      const percentageFromViewportCenterY = mouseY / viewportCenterY

      gsap.to(element, {
        x: percentageFromViewportCenterX * -elementDistance,
        y: percentageFromViewportCenterY * -elementDistance,
        duration: 1,
        overwrite: true,
      })
    }

    const scrollTrigger = ScrollTrigger.create({
      trigger: element,
      invalidateOnRefresh: true,
      // markers: imageForeground && imageForeground.childImageSharp.gatsbyImageData.images.fallback.src === '/static/c39bd521c0411d85158afdb8d4983dad/64d34/Group%201037.png',
      onToggle: self => {
        self.refresh()
        if (self.isActive) {
          window.addEventListener("mousemove", updateMouse)
          gsap.ticker.add(animateMouse)
        } else {
          window.removeEventListener("mousemove", updateMouse)
          gsap.ticker.remove(animateMouse)
        }
      },
    })
  }, [])

  css.figure = css`
    position: absolute;
    margin: 0;

    ${fluidRange(
      {
        prop: "top",
        fromSize: `${min.top}px`,
        toSize: `${max.top}px`,
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    ${fluidRange(
      {
        prop: "left",
        fromSize: `${min.left}px`,
        toSize: `${max.left}px`,
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    ${fluidRange(
      {
        prop: "width",
        fromSize: `${width / 2}px`,
        toSize: `${width}px`,
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}

    &:before {
      content: "";
      display: block;
      padding-bottom: ${percentage(height, width, "%")};
    }
  `

  css.image = css`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    ${"" /* background-color: ${background}; */}
  `

  return (
    <figure ref={ref} css={css.figure} {...other}>
      <div ref={refImage} css={css.image}>
        {children}
      </div>
    </figure>
  )
}

function FrontPageCaseStudiesItemMotion({ trigger, color, label, children }) {
  const theme = useTheme()

  useEffect(() => {
    const scrollTriggerPointerEvents = ScrollTrigger.create({
      id: label,
      trigger: trigger ? trigger.current : null,
      start: "25% 75%",
      end: `75% 25%`,
      scrub: true,
      toggleClass: 'cursor--active'
    })

    return () => {
      scrollTriggerPointerEvents.kill()
    }
  }, [theme, color, trigger])

  return <>{children}</>
}
